import { defineStore } from 'pinia'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'
import type { INotification } from '~/types/INotification'

export const useNotificationsStore = defineStore('notifications', () => {
  const {
    data: notifications,
    pending,
    execute: fetch
  } = useAuthenticatedFetch<INotification[]>('/api/v1/shop/notifications')
  const { execute: markAsRead } = useAuthenticatedFetch('/api/v1/shop/notifications/read', {
    method: 'POST'
  })
  const hasUnread = computed(() => {
    if (Array.isArray(notifications.value)) {
      return notifications.value.some(n => !n.is_viewed)
    }
    return false
  })
  return {
    fetch,
    pending,
    hasUnread,
    markAsRead,
    notifications
  }
})
