<script setup lang="ts">
import { type ICounterparty } from '~/types/ICounterparty'
import { useCounterpartiesByNameStore } from '~/stores/useCounterpartiesByNameStore'

const counterpartiesStore = useCounterpartiesByNameStore()
const counterpartyContractsStore = useCounterpartyContractsStore()
const router = useRouter()
const { counterpartyId, counterpartyContractId } = useCounterpartyCookie()
const counterparty = ref<ICounterparty | null>(null)
const counterpartyName = ref('')

const apply = () => router.go(0)
watch(counterparty, async (newValue) => {
  if (newValue) {
    if (newValue.id !== counterpartyId.value) {
      counterpartyContractId.value = newValue.main_contract_id
    }
    counterpartyId.value = newValue.id
    await counterpartyContractsStore.fetch(newValue.id)
    if (counterpartyId.value !== counterparty.value?.id || !counterpartyContractId.value) {
      const mainContract = counterpartyContractsStore.contracts.find((c) => c.is_main)
      if (mainContract) {
        counterpartyContractId.value = mainContract.id
      } else if (counterpartyContractsStore.contracts && counterpartyContractsStore.contracts.length) {
        counterpartyContractId.value = counterpartyContractsStore.contracts[0].id
        apply()
      }
    }
  }
})

watch(counterpartyName, (v)=> {
  if(typeof counterpartyName.value === 'object') {
    counterparty.value = counterpartyName.value
  }
}, {immediate: true})

const fetchCounterpartiesByName = () => {
  if (counterpartyName.value.length > 1) {
    counterpartiesStore.counterparty_name = counterpartyName.value
    counterpartiesStore.fetch()
  }
}
</script>

<template>
  <div class="w-8/12 lg:w-[300px]">
    <Dropdown
      show-clear
      @input="fetchCounterpartiesByName"
      editable
      :loading="counterpartiesStore.pending"
      placeholder="Контрагент"
      class="w-full text-left rounded-full"
      v-model="counterpartyName"
      :options="counterpartiesStore.counterparties"
      option-label="name"
    >
      <template #option="slotProps">
        <div
          class="whitespace-normal font-thin text-xs sm:font-normal sm:text-sm w-[220px] sm:w-[400px] md:w-[470px] lg:w-[270px]"
          :title="slotProps.option.name"
        >
          {{ slotProps.option.name }}
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<style scoped>

</style>
