<template>
  <form class="h-full">
    <div class="p-fluid h-full">
      <span class="p-input-icon-right h-full">
        <InputText
          @input="fetchDebounce"
          v-model="itemsStore.item_name"
          :placeholder="$t('Search')"
          class="rounded-full h-full p-4 lg:p-2"
        />
      </span>
    </div>
  </form>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'

const itemsStore = useItemsStore()
const fetchDebounce = debounce(itemsStore.fetch, 400)
</script>
