<script setup lang="ts">
import { format, parseISO } from 'date-fns'
import type { INotification } from '~/types/INotification'

const props = withDefaults(defineProps<{
  modelValue: boolean
  notifications: INotification[]
}>(), {
  modelValue: false,
  notifications: () => ([])
})

const emit = defineEmits<{
  (e: 'update:modelValue', v: boolean): void
}>()

const { modelValue } = useVModels(props, emit)

const rootRef = ref(null)
onClickOutside(rootRef, (e: PointerEvent) => {
  if (e.target.id !== 'notificationsIcon') {
    modelValue.value = false
  }
})
</script>

<template>
  <div
    ref="rootRef"
    :class="['flex flex-col gap-2 min-w-96 bg-white rounded-3xl shadow-2xl p-2 z-50', {'absolute': modelValue, 'hidden': !modelValue}]">
    <div v-if="notifications?.length" class="max-h-[600px] overflow-y-scroll">
      <template v-for="{title, message, created_at, is_viewed} in notifications">
        <div class="flex p-3 rounded-xl hover:bg-neutral-100 transition-colors cursor-default">
          <div
            :class="['rounded-lg min-h-16 h-16 min-w-16 w-16 flex items-center justify-center bg-white', {'text-[#CE4792]': !is_viewed}]">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 21.0905C6 20.0741 7.01696 19.1592 7.89295 18.2546C8.5575 17.5532 8.73874 16.1099 8.81929 14.9409C8.88977 10.9361 9.9168 8.35431 12.5951 7.37851C12.9274 6.04695 14.0148 5 15.495 5C16.9852 5 18.0625 6.04695 18.4049 7.37851C21.0832 8.35431 22.1102 10.9361 22.1807 14.9409C22.2613 16.1099 22.4425 17.5532 23.107 18.2546C23.973 19.1592 25 20.0741 25 21.0905C25 21.8325 24.4261 22.3204 23.4796 22.3204H19.2305C19.15 24.2924 17.6296 26 15.495 26C13.3704 26 11.85 24.2822 11.7695 22.3204H7.5204C6.56386 22.3204 6 21.8325 6 21.0905ZM7.95337 20.7856H23.0366V20.6636C22.8553 20.3688 22.2613 19.7691 21.7276 19.1796C21.0127 18.3664 20.6704 17.0552 20.5798 15.0731C20.4992 10.6312 19.1902 9.2183 17.4584 8.74056C17.2067 8.67957 17.0758 8.5576 17.0657 8.29332C17.0254 7.23621 16.4314 6.49419 15.495 6.49419C14.5686 6.49419 13.9645 7.23621 13.9343 8.29332C13.9242 8.5576 13.7833 8.67957 13.5315 8.74056C11.8098 9.2183 10.5008 10.6312 10.4202 15.0731C10.3296 17.0552 9.98728 18.3664 9.26232 19.1796C8.73874 19.7691 8.13461 20.3688 7.95337 20.6636V20.7856ZM13.3402 22.3204C13.4107 23.7028 14.2867 24.5668 15.495 24.5668C16.7133 24.5668 17.5792 23.7028 17.6598 22.3204H13.3402Z"
                fill="currentColor"/>
            </svg>
          </div>
          <div class="relative flex flex-col ml-2 pb-4">
            <p class="font-medium">{{ title }}</p>
            <p class="leading-4">{{ message }}</p>
            <span class="absolute -bottom-2 left-0 whitespace-nowrap text-neutral-400">
            {{ format(parseISO(created_at), 'd MMMM yyyy в HH:mm') }}
          </span>
          </div>
        </div>
      </template>
    </div>
    <template v-else>
      <div class="flex flex-col text-[#CE4792] items-center justify-center aspect-square">
        <svg width="100" height="100" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 21.0905C6 20.0741 7.01696 19.1592 7.89295 18.2546C8.5575 17.5532 8.73874 16.1099 8.81929 14.9409C8.88977 10.9361 9.9168 8.35431 12.5951 7.37851C12.9274 6.04695 14.0148 5 15.495 5C16.9852 5 18.0625 6.04695 18.4049 7.37851C21.0832 8.35431 22.1102 10.9361 22.1807 14.9409C22.2613 16.1099 22.4425 17.5532 23.107 18.2546C23.973 19.1592 25 20.0741 25 21.0905C25 21.8325 24.4261 22.3204 23.4796 22.3204H19.2305C19.15 24.2924 17.6296 26 15.495 26C13.3704 26 11.85 24.2822 11.7695 22.3204H7.5204C6.56386 22.3204 6 21.8325 6 21.0905ZM7.95337 20.7856H23.0366V20.6636C22.8553 20.3688 22.2613 19.7691 21.7276 19.1796C21.0127 18.3664 20.6704 17.0552 20.5798 15.0731C20.4992 10.6312 19.1902 9.2183 17.4584 8.74056C17.2067 8.67957 17.0758 8.5576 17.0657 8.29332C17.0254 7.23621 16.4314 6.49419 15.495 6.49419C14.5686 6.49419 13.9645 7.23621 13.9343 8.29332C13.9242 8.5576 13.7833 8.67957 13.5315 8.74056C11.8098 9.2183 10.5008 10.6312 10.4202 15.0731C10.3296 17.0552 9.98728 18.3664 9.26232 19.1796C8.73874 19.7691 8.13461 20.3688 7.95337 20.6636V20.7856ZM13.3402 22.3204C13.4107 23.7028 14.2867 24.5668 15.495 24.5668C16.7133 24.5668 17.5792 23.7028 17.6598 22.3204H13.3402Z"
            fill="currentColor"/>
        </svg>
        <p class="text-black font-medium">Уведомлений пока нет</p>
      </div>
    </template>
  </div>
</template>

<style scoped>

</style>
